@font-face {
  font-family: 'calibre-semibold';
  src: local('calibre-semibold'),
    url(./fonts/calibre-semibold.woff2) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'calibre-regular';
  src: local('calibre-regular'),
    url(./fonts/calibre-regular.woff2) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'calibre-medium';
  src: local('calibre-medium'),
    url(./fonts/calibre-medium.woff2) format('truetype');
  font-display: swap;
}
