.containerBackground {
  background-image: none;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: top center;
  overflow: hidden;
  min-height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
}

.bg-primary {
  background-image: url(primary.jpg);
}

.bg-pier {
  background-image: url(pier-mobile.jpg);
}

@media (min-width: 600px) {
  .bg-pier {
    background-image: url(pier.jpg);
  }
}

.bg-not-found {
  background-image: url(primary.jpg);
  position: absolute;
  left: 0;
  right: 0;
  margin-top: -40px;
}

@media (max-width: 800px) {
  .hideBackground {
    background-image: none;
  }
  .containerBackground {
    background-position: center center;
  }
}

.content {
  margin: 0 auto;
  position: relative;
  flex: 1;
  border-radius: 6px;
}

.content-default {
  max-width: 684px;
}

.content-large {
  max-width: 920px;
}

.contentPadding {
  padding: 0 24px;
}

@media (min-width: 600px) {
  .contenttype-centered {
    margin-top: 15vh;
    margin-bottom: 15vh;
    min-height: 50vh;
  }

  .contenttype-centered-top {
    margin-top: 80px;
    margin-bottom: 15vh;
    min-height: 50vh;
  }
}

@media (min-width: 600px) {
  .contentPadding {
    padding: 24px 64px;
  }
}
